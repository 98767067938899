<template>
  <container>
    <card-section>
      <template #title> Copy + transfer assemblies </template>
      <template #subtitle> Also copies related items, upgrades, and files. </template>
      <div class="flex justify-start items-center" style="width: 100%">
        <search-field v-model="searchPhrase" class="left" />

        <div class="flex items-center justify-center" style="margin-left: auto">
          <choose
            class="sm"
            :return-array="false"
            schema="company:company_id"
            placeholder="Global"
            :allowCreate="false"
            v-model="fromCompanyId"
          />
          <font-awesome-icon icon="arrow-right" class="text-info" />
          <choose
            class="sm default"
            :return-array="false"
            :btnClass="{
              round: true
            }"
            placeholder="Global (avail. to every company)"
            schema="company:company_id"
            :allowCreate="false"
            v-model="companyId"
          />
        </div>
      </div>
    </card-section>

    <card-section>
      <card-list>
        <card-list-item v-for="assembly in set" :key="assembly.assembly_id">
          <div class="flex justify-start items-center" style="width: 100%">
            <div class="flex flex-col items-start justify-center">
              <span>{{ assembly.assembly_name }}</span>
              <span class="badge default mt-1">{{ $f.currency(assembly.quote_subtotal_net) }}</span>
            </div>

            <btn
              class="default round sm"
              :action="() => transfer(assembly.assembly_id, companyId)"
              style="margin-left: auto"
            >
              <template #icon>
                <font-awesome-icon icon="arrow-right" />
              </template>
              Copy
            </btn>
          </div>
        </card-list-item>
      </card-list>
    </card-section>
  </container>
</template>

<script>
import BodyMixin from '../mixins/Body'

export default {
  mixins: [BodyMixin],

  data() {
    return {
      companyId: null,
      fromCompanyId: 2,
      searchPhrase: null,
      set: []
    }
  },

  watch: {
    searchPhrase() {
      this.throttleFetch()
    },
    fromCompanyId() {
      this.throttleFetch()
    }
  },

  methods: {
    async transfer(assembly, company) {
      const scope = company ? { company } : {}
      this.$store.dispatch('alert', {
        message: 'Starting transfer, this can take several minutes..'
      })
      try {
        await this.$store.dispatch('ajax', {
          path: `assembly/copyToScope/${assembly}`,
          data: {
            scope
          }
        })
      } catch (e) {
        this.$store.dispatch('alert', {
          error: true,
          message: e.userMessage || e.message
        })
        throw e
      }
      this.$store.dispatch('alert', {
        message: 'Transfer complete!'
      })
    },

    async throttleFetch() {
      return c.throttle(() => this.fetch(), { delay: 500 })
    },

    async fetch() {
      const { set } = await this.$store.dispatch('Assembly/search', {
        searchPhrase: this.searchPhrase,
        filters: {
          company_id: this.fromCompanyId || 'NULL'
        }
      })

      this.set = set
    }
  },

  mounted() {
    this.fetch()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
