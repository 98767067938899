<template>
  <Page>
    <super-items-body />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import SuperItemsBody from '../bodies/SuperItems.vue'

export default {
  mixins: [PageMixin],
  name: 'SuperItems',
  components: {
    SuperItemsBody
  }
}
</script>
